import { api } from '../utils'
import { notificate } from './notification'
import { beforeSend, afterResponse } from './'

const identifier = 'brand'

export const loadBrands = token => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.get('admin/brands', {}, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'SET_BRANDS',
            data: result.data
          })
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createBrand = (data, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.post('admin/brands', data, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'SET_BRAND',
            data: result.data
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateBrand = (data, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.put(`admin/brands/${data.id}`, data, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'UPDATE_BRAND',
            data: result.data
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const deleteBrand = (id, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.delete(`admin/brands/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'DELETE_BRAND',
            id
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
