export default {
  titles: {
    login: 'Login'
  },
  accountLinks: {
    site: 'Voltar para site'
  },
  user: {
    name: 'Nome',
    email: 'E-mail',
    password: 'Senha',
    password_confirmation: 'Confirmação da senha',
    status: 'Ativo'
  },
  actions: {
    enter: 'Entrar',
    save: 'Salvar',
    areYouSure: 'Você tem certeza que deseja deletar?',
    dropFiles: 'Solte as imagens aqui',
    dropImageOrVideo: 'Solte a imagem ou o video aqui',
    orClickToChoose: 'Ou clique para escolher',
    import: 'Importar'
  },
  validations: {
    login: {
      email: {
        validEmail: 'E-mail inválido'
      },
      password: {
        string: 'Digite sua senha',
        minLength: 'Sua senha precisa ter ao menos 6 caracteres'
      }
    },
    users: {
      name: {
        string: 'Digite o nome',
        minLength: 'Digite o nome'
      },
      email: {
        validEmail: 'E-mail inválido'
      },
      password: {
        string: 'Digite a senha',
        minLength: 'A senha precisa ter ao menos 6 caracteres'
      },
      password_confirmation: {
        string: 'Digite a confirmação da senha',
        minLength: 'Digite a confirmação da senha'
      }
    },
    cars: {
      brand_id: {
        number: 'Escolha um fabricante'
      },
      model_id: {
        number: 'Escolha um modelo'
      },
      version_id: {
        number: 'Escolha uma versão'
      },
      year_id: {
        number: 'Escolha um ano'
      },
      fuel_id: {
        number: 'Escolha um tipo de combustível'
      },
      gear_id: {
        number: 'Escolha um tipo de transmissão'
      },
      color_id: {
        number: 'Escolha uma cor'
      },
      km: {
        minLength: 'Digite a kilometragem ou 0 para carro novo',
        number: 'Digite a kilometragem ou 0 para carro novo'
      },
      ports: {
        number: 'Digite o número de portas'
      },
      registration_plate: {
        validRegistrationPlate: 'Número da placa inválida, formato aceito: AAA-9999'
      },
      price: {
        number: 'O preço precisa ser um número válido'
      },
      description: {
        minLength: 'Digite uma descrição'
      }
    },
    banners: {
      file: {
        minLength: 'Escolha um arquivo'
      },
      name: {
        minLength: 'Dê um nome para a campanha'
      },
      link: {
        minLength: 'Digite o link'
      },
      local: {
        minLength: 'Selcione o local do banner'
      }
    }
  },
  car: {
    newItem: 'Novo item',
    brands: 'Fabricantes',
    models: 'Modelos',
    versions: 'Versões',
    years: 'Anos',
    fuels: 'Tipos de combustíveis',
    gears: 'Tipos de transimissões',
    colors: 'Cores',
    fuel: 'Combustível',
    gear: 'Tipo de transimissão',
    color: 'Cor',
    optionals: 'Opcionais',
    km: 'Kilometragem',
    ports: 'Portas',
    registration_plate: 'Placa',
    price: 'Preço',
    description: 'Descrição',
    status: 'Ativo',
    highlighted: 'Destaque',
    sold: 'Vendido',
    sold_date: 'Data da venda'
  },
  banner: {
    file: 'Imagem ou Video',
    fileHelp: 'Imagens aceitas: <strong>png</strong> e <strong>jpg</strong>. Videos aceitos: <strong>mp4</strong>. Tamanho ideal <strong>2000x378</strong>',
    local: 'Local do banner',
    link: 'Link',
    name: 'Campanha',
    status: 'Ativo'
  },
  syncCar: {
    title: 'Se você tem um cadastro no <strong>Vidoca Shopping do Carro</strong>, pode importar seus carros, basta colocar sua API Key do seu cadastro',
    apiKey: 'API Key'
  }
}
