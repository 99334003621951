import fecha from 'fecha'

/**
 * Utilidades de data
 */
const date = {
  /**
   * Formata data e hora
   * @param {string} value
   * @returns {string}
   */
  format (value) {
    const date = fecha.parse(value, 'YYYY-MM-DD HH:mm:ss')
    return fecha.format(date, 'DD/MM/YYYY HH:mm:ss')
  },

  /**
   * Formata data apenas
   * @param {string} value
   * @returns {string}
   */
  formatDateOnly (value) {
    const date = fecha.parse(value, 'YYYY-MM-DD HH:mm:ss')
    return fecha.format(date, 'DD/MM/YYYY')
  },

  /**
   * Converte data para formato banco de dados
   * @param {string} value
   * @returns {string}
   */
  toDateOnly (value) {
    try {
      const date = fecha.parse(value, 'DD/MM/YYYY')
      return date
    } catch {
      return null
    }
  }
}

export default date
