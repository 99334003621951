import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { loadCar, updateCar, clearCar } from '../../actions/car'
import { notificate } from '../../actions/notification'
import { CarForm, Spinner } from '../'
import { carValidator } from '../../validators'
import { links } from '../../utils'

class CarEdit extends Component {
  state = {
    errors: {}
  };

  componentDidMount () {
    this._loadData()
  };

  componentDidUpdate () {
    const { redirect } = this.props.car
    if (redirect) {
      return this.props.history.push(redirect)
    }
  };

  render () {
    const { errors } = this.state
    const { car, sending } = this.props.car
    const hasCar = car && car.id

    return (
      <div className="main-content">
        <header className="main-content__header">
          <h1 className="main-content__title">Editar carro</h1>
          <Link to={links.get('home')} className="main-content__cancel">Voltar</Link>
        </header>
        {sending && !hasCar &&
          <div className="spinner-box">
            <Spinner />
          </div>
        }
        {hasCar &&
          <CarForm
            sending={sending}
            editCar={car}
            errors={errors}
            onSubmit={this._handleSubmit}
          />
        }
      </div>
    )
  };

  _loadData = () => {
    const { token } = this.props
    const id = this.props.history.location.pathname.split('/').pop()
    this.props.clearCar()
    this.props.loadCar(id, token)
  };

  _handleSubmit = data => {
    const errors = {}
    this.setState({ errors }, () => {
      carValidator.update(data).then(result => {
        if (result.success) {
          const { token } = this.props
          this.props.updateCar(data, token)
        } else {
          this.setState({ errors: result.errors })
        }
      }).catch(error => {
        this.props.notificate(error.message)
      })
    })
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.user.user.token,
    car: state.car
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadCar: (id, token) => dispatch(loadCar(id, token)),
    updateCar: (data, token) => dispatch(updateCar(data, token)),
    clearCar: () => dispatch(clearCar()),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarEdit)
