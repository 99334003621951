import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createBanner } from '../../actions/banner'
import { notificate } from '../../actions/notification'
import { BannerForm } from '../'
import { bannerValidator } from '../../validators'
import { links } from '../../utils'

class BannerAdd extends Component {
  state = {
    errors: {}
  };

  componentDidUpdate () {
    const { redirect } = this.props.banner
    if (redirect) {
      return this.props.history.push(redirect)
    }
  };

  render () {
    const { errors } = this.state
    const { sending } = this.props.banner

    return (
      <div className="main-content">
        <header className="main-content__header">
          <h1 className="main-content__title">Adicionar banner</h1>
          <Link to={links.get('banners')} className="main-content__cancel">Voltar</Link>
        </header>
        <BannerForm
          sending={sending}
          errors={errors}
          onSubmit={this._handleSubmit}
        />
      </div>
    )
  };

  _handleSubmit = data => {
    const errors = {}
    this.setState({ errors }, () => {
      bannerValidator.create(data).then(result => {
        if (result.success) {
          const { token } = this.props
          this.props.createBanner(data, token)
        } else {
          this.setState({ errors: result.errors })
        }
      }).catch(error => {
        this.props.notificate(error.message)
      })
    })
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.user.user.token,
    banner: state.banner
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createBanner: (data, token) => dispatch(createBanner(data, token)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerAdd)
