import numeral from 'numeral'

export default {
  moneyToDecimal (value) {
    let newValue = value.toString().replace(/[^0-9,]/gi, '')
    newValue = newValue.replace(',', '.')
    return +newValue
  },
  formatMoney (value) {
    const formatedValue = numeral(+value).format('$0.0,')
    return formatedValue
  }
}
