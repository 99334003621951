const initialState = {
  list: [],
  sending: false
}

const identifier = 'color'

export default (state = initialState, action) => {
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case 'BEFORE_SEND':
      if (action.identifier === identifier) {
        nextState = Object.assign(nextState, {
          sending: true
        })
      }
      break

    case 'AFTER_RESPONSE':
      if (action.identifier === identifier) {
        nextState = Object.assign(nextState, {
          sending: false
        })
      }
      break

    case 'SET_COLORS':
      nextState = Object.assign(nextState, {
        list: action.data
      })
      break

    case 'SET_COLOR':
      nextState = Object.assign(nextState, {
        list: [action.data].concat(nextState.list)
      })
      break

    case 'UPDATE_COLOR':
      nextState = Object.assign(nextState, {
        list: nextState.list.map(item => {
          if (+item.id === +action.data.id) {
            item = { ...action.data }
          }
          return item
        })
      })
      break

    case 'DELETE_COLOR':
      nextState = Object.assign(nextState, {
        list: nextState.list.filter(item => {
          return +item.id !== +action.id
        })
      })
      break

    default:
      nextState = state
      break
  }

  return nextState
}
