import React, { Component } from 'react'
import { translate } from '../locales'

class AccountLinks extends Component {
  render () {
    const list = this._getLinks().map((link, index) => (
      <li key={index}>
        <a href={link.name} className="account-links__link">{translate(link.label)}</a>
      </li>
    ))
    return (
      <ul className="account-links">
        {list}
      </ul>
    )
  };

  _getLinks = () => {
    const links = [{
      label: 'accountLinks.site',
      name: 'http://tedescoautos.com.br'
    }]
    return links
  }
}

export default AccountLinks
