export const beforeSend = (identifier = null) => {
  return {
    type: 'BEFORE_SEND',
    identifier
  }
}

export const afterResponse = (identifier = null) => {
  return {
    type: 'AFTER_RESPONSE',
    identifier
  }
}

export const setPaging = (paging) => {
  return {
    type: 'SET_PAGING',
    paging
  }
}

