import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
// import CurrencyInput from 'react-currency-input'
import FormFile from './FormFile'

class FormControl extends Component {
  static defaultProps = {
    errors: [],
    type: 'text',
    name: null,
    help: null,
    label: null,
    value: '',
    mask: null,
    options: [],
    maxLength: '',
    onChange: () => {},
    onEnter: () => {}
  };

  render () {
    return (
      <div className={this._getClasses()}>
        {this._getInput()}
        {this._getErrors()}
        {this.props.children}
      </div>
    )
  };

  _getInput = () => {
    const {
      label,
      value,
      name,
      type,
      mask,
      help,
      options,
      maxLength
    } = this.props
    let className = 'form__input__input'
    if (type === 'checkbox') {
      className = ''
    }

    let helpTag = null
    if (help) {
      helpTag = (
        <div className="form__help" dangerouslySetInnerHTML={{ __html: help }} />
      )
    }

    switch (type) {
      case 'checkbox':
        const checked = value === true || +value === 1
        return (
          <div>
            <div className="form__checkbox">
              <label className="form__label">
                <input
                  value={value}
                  type={type}
                  name={name}
                  checked={checked}
                  className={className}
                  onChange={this._onChange}
                  onKeyDown={this._handleKeyDown}
                />
                <span>{label}</span>
              </label>
            </div>
            {helpTag}
          </div>
        )

      case 'radio':
        const items = options.map((item, index) => {
          const checked = value === item.value
          return (
            <label
              key={index}
              className="form__label"
            >
              <input
                value={item.value}
                type={type}
                name={name}
                checked={checked}
                onChange={this._onChange}
                onKeyDown={this._handleKeyDown}
              />
              <span>{item.label}</span>
            </label>
          )
        })
        return (
          <div>
            <label>{label}</label>
            <div className="form__radios">
              {items}
            </div>
            {helpTag}
          </div>
        )

      case 'file':
        return (
          <div>
            <label htmlFor={name} className="form__label">{label}</label>
            <FormFile onChange={this._onChangeFile} />
            {helpTag}
          </div>
        )

      default:
        if (mask) {
          return (
            <div>
              <label htmlFor={name} className="form__label">{label}</label>
              <div className="form__input">
                <MaskedInput
                  mask={this._translateMask(mask)}
                  value={value}
                  name={name}
                  className={className}
                  onChange={this._onChange}
                />
              </div>
              {helpTag}
            </div>
          )
        } else {
          let inputParams = {
            value: value,
            type: type,
            name: name,
            className: className,
            onChange: this._onChange,
            onKeyDown: this._handleKeyDown
          }
          if (maxLength) {
            inputParams.maxLength = maxLength
          }
          return (
            <div>
              <label htmlFor={name} className="form__label">{label}</label>
              <div className="form__input">
                <input {...inputParams} />
              </div>
              {helpTag}
            </div>
          )
        }
    }
  };

  _getClasses = () => {
    let classes = ['form__control']
    if (this.props.errors.length > 0) {
      classes.push('form__control--error')
    }
    return classes.join(' ')
  };

  _getErrors = () => {
    if (this.props.errors.length > 0) {
      const errors = this.props.errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))
      return <ul className="form__control__errors">{errors}</ul>
    }
  };

  _onChange = event => {
    let value = event.target.value
    if (event.target.type === 'checkbox') {
      value = event.target.checked
    }
    this.props.onChange(this.props.name, value)
  };

  _handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.props.onEnter()
    }
  };

  _translateMask = (name) => {
    const masks = {
      money: createNumberMask({
        prefix: 'R$ ',
        decimalSymbol: ',',
        thousandsSeparatorSymbol: '.',
        includeThousandsSeparator: true,
        allowDecimal: true,
        requireDecimal: true
      }),
      decimal: createNumberMask({
        prefix: '',
        thousandsSeparatorSymbol: '.',
        includeThousandsSeparator: true,
        allowDecimal: false,
        requireDecimal: false
      }),
      registration_plate: [
        /[a-z]/i, /[a-z]/i, /[a-z]/i, '-', /\d/, /\d/, /\d/, /\d/
      ],
      ports: [
        /\d/
      ],
      date: [
        /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/
      ]
    }
    return masks[name] || []
  };

  _onChangeFile = file => {
    this.props.onChange(this.props.name, file.name)
  };

  _getSafeProps = () => {
    let props = { ...this.props }
    delete props.onEnter
    return props
  };
}

export default FormControl
