import { api } from '../utils'
import { notificate } from './notification'
import { beforeSend, afterResponse } from './'

const _setUser = data => ({
  type: 'SET_USER',
  data
})

const _setEditUser = data => ({
  type: 'SET_EDIT_USER',
  data
})

const _setUsers = (data, paging) => ({
  type: 'SET_USERS',
  data,
  paging
})

const _addUser = data => ({
  type: 'ADD_USER',
  data
})

const _editUser = data => ({
  type: 'UPDATE_USER',
  data
})

const _deleteUser = id => ({
  type: 'DELETE_USER',
  id
})

export const checkLogin = (token) => {
  return dispatch => {
    if (token) {
      dispatch(beforeSend())
      api.post('admin/users/verify-token', { token })
        .then(result => {
          dispatch(afterResponse())
          if (result.success) {
            dispatch(_setUser(result.data))
          } else {
            dispatch(notificate(result.message, 'error'))
            dispatch(_setUser(null))
          }
        })
        .catch(error => {
          dispatch(notificate(error.message, 'error'))
          dispatch(_setUser(null))
          dispatch(afterResponse())
        })
    } else {
      dispatch(_setUser(null))
    }
  }
}

export const login = (data) => {
  return dispatch => {
    dispatch(beforeSend())
    api.post('admin/users/login', data)
      .then(result => {
        dispatch(afterResponse())
        if (result.success) {
          dispatch(_setUser(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
          dispatch(_setUser(null))
        }
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(_setUser(null))
        dispatch(afterResponse())
      })
  }
}

export const logout = (token) => {
  return dispatch => {
    dispatch(_setUser(null))
  }
}

export const loadUsers = (filters, token) => {
  return dispatch => {
    dispatch(beforeSend())
    api.get('admin/users', filters, token)
      .then(result => {
        dispatch(afterResponse())
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(_setUsers(result.data, result.paging))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse())
      })
  }
}

export const loadUser = (id, token) => {
  return dispatch => {
    dispatch(beforeSend())
    api.get(`admin/users/${id}`, {}, token)
      .then(result => {
        dispatch(afterResponse())
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(_setEditUser(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse())
      })
  }
}

export const createUser = (data, token) => {
  return dispatch => {
    dispatch(beforeSend())
    api.post('admin/users', data, token)
      .then(result => {
        dispatch(afterResponse())
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(_addUser(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse())
      })
  }
}

export const updateUser = (data, token) => {
  return dispatch => {
    dispatch(beforeSend())
    api.put(`admin/users/${data.id}`, data, token)
      .then(result => {
        dispatch(afterResponse())
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(_editUser(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse())
      })
  }
}

export const deleteUser = (id, token) => {
  return dispatch => {
    dispatch(beforeSend())
    api.delete('admin/users/' + id, {}, token)
      .then(result => {
        dispatch(afterResponse())
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(_deleteUser(id))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse())
      })
  }
}

export const clearUser = () => {
  return dispatch => {
    dispatch(_setEditUser(null))
  }
}
