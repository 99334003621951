import React, { Component } from 'react'
import { links } from '../utils'
import { Link } from 'react-router-dom'
import { translate } from '../locales'

class Car extends Component {
  static defaultProps = {
    car: {},
    onDelete: () => {}
  };

  render () {
    const { car } = this.props
    const image = this._getImage()
    return (
      <div
        className="car"
      >
        {image &&
          <div className="car__image">
            <img src={image} alt="Foto" className="car__image__img" />
          </div>
        }
        <div className="car__info">
          <div className="car__info__name">{car.brand.name} {car.model.name}</div>
          <div className="car__info__version">{car.version.name}</div>
          <div className="car__info__half">
            <div className="car__info__year">{car.year.name}</div>
            <div className="car__info__km">KM {car.km}</div>
          </div>
          {car.sold &&
            <div className="car__info__sold">{translate('car.sold')}</div>
          }
        </div>
        <div className="car__actions">
          <Link to={links.get('editCar', { id: car.id })} className="car__actions__link car__actions__link--edit">Editar</Link>
          <span className="car__actions__link car__actions__link--delete" onClick={this._confirmDelete}>Deletar</span>
        </div>
      </div>
    )
  };

  _confirmDelete = event => {
    event.preventDefault()
    if (window.confirm('Tem certeza?')) {
      this.props.onDelete()
    }
  };

  _getImage = () => {
    const { car } = this.props
    let image = null
    if (car.images && car.images.length > 0) {
      image = car.images[0].thumb_url
    }
    return image
  };
}

export default Car
