import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import { Header, Notification } from '../'
import { checkLogin } from '../../actions/user'

class AppLayout extends Component {
  componentDidMount() {
    const { checkLogin, cookies } = this.props
    const token = cookies.get('token')
    checkLogin(token)
  };

  render () {
    return (
      <div className="app-layout">
        <Notification />
        <Header user={this.props.user} />
        <div className="app-layout__content">
          {this.props.children}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { ...state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkLogin: token => dispatch(checkLogin(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withCookies(AppLayout)
)
