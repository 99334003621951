import routes from '../routes'

export default {
  get (name, params) {
    const link = routes.routes.find(item => item.name === name)
    let path = '/'
    if (link) {
      path = link.path
      if (params) {
        for (let i in params) {
          const value = params[i]
          path = path.replace(`:${i}`, value)
        }
      }
    }
    return path
  }
}
