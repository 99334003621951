import { api } from '../utils'
import { notificate } from './notification'
import { beforeSend, afterResponse } from './'

const identifier = 'car'

export const loadCars = (filters = {}, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.get('admin/cars', filters, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'SET_CARS',
            data: result.data,
            paging: result.paging
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createCar = (data, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.post('admin/cars', data, token)
      .then(result => {
        dispatch(afterResponse(identifier))
        if (result.success) {
          dispatch({
            type: 'ADD_CAR',
            data: result.data
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const deleteCar = (id, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.delete(`admin/cars/${id}`, {}, token)
      .then(result => {
        dispatch(afterResponse(identifier))
        if (result.success) {
          dispatch({
            type: 'DELETE_CAR',
            id
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadCar = (id, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.get(`admin/cars/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'SET_CAR',
            data: result.data
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateCar = (data, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.put(`admin/cars/${data.id}`, data, token)
      .then(result => {
        dispatch(afterResponse(identifier))
        if (result.success) {
          dispatch({
            type: 'UPDATE_CAR',
            data: result.data
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const clearCar = () => {
  return dispatch => {
    dispatch({
      type: 'SET_CAR',
      data: {}
    })
  }
}
