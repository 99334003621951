import { combineReducers } from 'redux'
import user from './user'
import notification from './notification'
import car from './car'
import brand from './brand'
import model from './model'
import version from './version'
import year from './year'
import fuel from './fuel'
import gear from './gear'
import color from './color'
import optional from './optional'
import banner from './banner'
import syncCar from './sync_car'

export default combineReducers({
  user,
  notification,
  car,
  brand,
  model,
  version,
  year,
  fuel,
  gear,
  color,
  optional,
  banner,
  syncCar
})
