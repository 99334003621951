import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { loadCars, deleteCar } from '../../actions/car'
import { syncCars } from '../../actions/sync_car'
import { notificate } from '../../actions/notification'
import { links } from '../../utils'
import { Car } from '../'
import { Spinner, FormControl } from '../'

class Dashboard extends Component {
  state = {
    filters: {
      limit: 18,
      page: 1,
      search: '',
      stock: ''
    }
  };

  componentDidMount () {
    this._loadData()
  };

  render () {
    const { filters } = this.state
    const { sending, paging, list } = this.props.car
    const importing = this.props.syncCar.sending
    return (
      <div className="main-content">
        <div className="button-and-filters">
          <Link className="button button--inline" to={links.get('addCar')}>Novo carro</Link>
          <div className="car-filters">
            <FormControl
              label='Filtrar por palavra-chave'
              value={filters.search}
              onChange={this._onChangeSearch}
            />
            <br />
            <div className="form-control">
              <label className="form__label">Filtrar estoque:</label>
              <FormControl
                name='stock'
                type='radio'
                value={filters.stock}
                options={this._getStockOptions()}
                onChange={this._onChangeStock}
              />
            </div>
          </div>
        </div>
        {sending &&
          <div className="spinner-box">
            <Spinner />
          </div>
        }
        <div className="car-list">
          {this._getCars()}
        </div>
        {paging && paging.pages > 1 &&
          <div className="pagination">
            <ReactPaginate
              pageCount={paging.pages}
              onPageChange={this._onPageChange}
              previousLabel='Anterior'
              nextLabel='Próxima'
            />
          </div>
        }
      </div>
    )
  };

  _loadData = () => {
    const { sending } = this.props.car
    if (!sending) {
      this.props.loadCars(this.state.filters, this.props.token)
    }
  }

  _getCars = () => {
    const { list, sending } = this.props.car
    if (sending) {
      return false
    }
    return list.map(item => {
      return (
        <Car
          key={item.id}
          car={item}
          onEdit={() => this._onEdit(item)}
          onDelete={() => this._onDelete(item)}
        />
      )
    })
  };

  _onDelete = item => {
    const { token } = this.props
    this.props.deleteCar(item.id, token)
  };

  _onPageChange = item => {
    const filters = {
      ...this.state.filters,
      page: item.selected + 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  };

  _onChangeSearch = (name, search) => {
    const filters = {
      ...this.state.filters,
      search
    }
    clearTimeout(this.searchInterval)
    this.setState({ filters }, () => {
      this.searchInterval = setTimeout(this._loadData, 400)
    })
  };

  _onSubmitSyncCars = data => {
    const { token } = this.props
    this.props.syncCars(data, token)
  };

  _getStockOptions = () => {
    return [{
      value: '',
      label: 'Todos'
    }, {
      value: 'in',
      label: 'Em estoque'
    }, {
      value: 'out',
      label: 'Vendidos'
    }]
  };

  _onChangeStock = (name, value) => {
    const filters = {
      ...this.state.filters,
      stock: value
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.user.user.token,
    car: state.car,
    syncCar: state.syncCar
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadCars: (filters, token) => dispatch(loadCars(filters, token)),
    deleteCar: (id, token) => dispatch(deleteCar(id, token)),
    syncCars: (data, token) => dispatch(syncCars(data, token)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
