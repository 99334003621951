import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { loadUsers, deleteUser } from '../../actions/user'
import { notificate } from '../../actions/notification'
import { links } from '../../utils'
import { User } from '../'
import { Spinner } from '../'

class UsersList extends Component {
  state = {
    filters: {
      limit: 10,
      page: 1
    }
  };

  componentDidMount () {
    this._loadData()
  };

  render () {
    const { sending, paging } = this.props.user
    return (
      <div className="main-content">
        <Link className="button button--inline" to={links.get('addUser')}>Novo usuário</Link>
        {sending &&
          <div className="spinner-box">
            <Spinner />
          </div>
        }
        <div className="user-list">
          {this._getUsers()}
        </div>
        {paging && paging.page &&
          <div className="pagination">
            <ReactPaginate
              pageCount={paging.pages}
              onPageChange={this._onPageChange}
              previousLabel='Anterior'
              nextLabel='Próxima'
            />
          </div>
        }
      </div>
    )
  };

  _loadData = () => {
    const { sending } = this.props.user
    if (!sending) {
      this.props.loadUsers(this.state.filters, this.props.token)
    }
  }

  _getUsers = () => {
    const { list, sending } = this.props.user
    if (sending) {
      return false
    }
    return list.map(item => {
      return (
        <User
          key={item.id}
          user={item}
          onEdit={() => this._onEdit(item)}
          onDelete={() => this._onDelete(item)}
        />
      )
    })
  };

  _onDelete = item => {
    const { token } = this.props
    this.props.deleteUser(item.id, token)
  };

  _onPageChange = item => {
    const filters = {
      ...this.state.filters,
      page: item.selected + 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.user.user.token,
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: (filters, token) => dispatch(loadUsers(filters, token)),
    deleteUser: (id, token) => dispatch(deleteUser(id, token)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersList)
