import React, { Component } from 'react'
import { connect } from 'react-redux'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import { Redirect } from 'react-router'
import { logout } from '../../actions/user'

class Logout extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  }

  componentWillMount () {
    const token = this.props.cookies.get('token')
    this.props.cookies.remove('token')
    this.props.logout(token)
  }

  render() {
    if (!this.props.user) {
      return <Redirect to={'/login'} />
    }
    return null
  }
}

const mapStateToProps = (state) => {
  return { ...state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: token => dispatch(logout(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withCookies(Logout)
)
