import React, { Component } from 'react'
import { translate } from '../locales'
import FormControl from './FormControl'
import ButtonSpinner from './ButtonSpinner'

class SyncCars extends Component {
  static defaultProps = {
    sending: false,
    onSubmit: () => {}
  };

  state = {
    api_key: ''
  };

  render () {
    const { state } = this
    const { sending } = this.props
    return (
      <div className="row row--medium-4">
        <div className="sync-cars">
          <form onSubmit={this._onSubmit}>
            <div>
              <p
                className="sync-cars__title"
                dangerouslySetInnerHTML={{ __html: translate('syncCar.title')}}
              />
              <FormControl
                value={state.api_key}
                name='api_key'
                onChange={this._onChange}
                label={translate('syncCar.apiKey')}
              />
              <ButtonSpinner
                disabled={sending}
                type='submit'
              >
                {translate('actions.import')}
              </ButtonSpinner>
            </div>
          </form>
        </div>
      </div>
    )
  };

  _onChange = (field, value) => {
    this.setState({ [field]: value })
  };

  _onSubmit = event => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  };
}

export default SyncCars
