import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { translate } from '../locales'
import Thumb from './Thumb'

function FormFile(props) {
  const [files, setFiles] = useState([])
  const maxFiles = 1

  const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
      })
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/jpg,image/png,video/mp4',
    onDrop: acceptedFiles => {
      acceptedFiles = acceptedFiles.splice(0, maxFiles)
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: file.preview || URL.createObjectURL(file)
        }))
      )
      let promises = acceptedFiles.map(item => {
        return getBase64(item).then(name => ({ name }))
      })
      Promise.all(promises).then(files => {
        props.onChange(files[0])
      })
    }
  })

  const { editCar } = props
  if (
    editCar &&
    editCar.id &&
    editCar.images &&
    editCar.images.length > 0 &&
    files.length === 0
  ) {
    setFiles(
      editCar.images.map(item => {
        return {
          id: item.id,
          preview: item.thumb_url,
          name: item.id
        }
      })
    )
  }

  const thumbs = files
    .filter(file => !file.remove)
    .map((file, index) => (
      <Thumb
        key={index}
        file={file}
        onDelete={file => {
          setFiles(files.map(item => {
            if (item.preview === file.preview) {
              item.remove = true
            }
            return item
          }))
          props.onChange({ name: '' })
        }}
      />
    ))

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files])

  return (
    <section className="photo-upload">
      <p className="photo-upload__title">{props.help}</p>
      <div {...getRootProps({ className: 'photo-upload__dropzone' })}>
        <input {...getInputProps()} />
        <h2>{translate('actions.dropImageOrVideo')}</h2>
        <p>{translate('actions.orClickToChoose')}</p>
      </div>
      {thumbs.length > 0 &&
        <div className="photo-upload__thumbs">
          {thumbs}
        </div>
      }
    </section>
  );
}

export default FormFile
