import React, { Component } from 'react'
import wheelIcon from '../assets/svg/wheel.svg'

class Spinner extends Component {
  render() {
    return (
      <div className="spinner">
        <img src={wheelIcon} alt='Loading...' />
      </div>
    );
  }
}

export default Spinner
