const initialState = {
  list: [],
  car: {},
  paging: {},
  sending: false,
  redirect: null
}

const identifier = 'car'

export default (state = initialState, action) => {
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case 'BEFORE_SEND':
      if (action.identifier === identifier) {
        nextState = Object.assign(nextState, {
          sending: true
        })
      }
      break

    case 'AFTER_RESPONSE':
      if (action.identifier === identifier) {
        nextState = Object.assign(nextState, {
          sending: false,
          redirect: null
        })
      }
      break

    case 'SET_CARS':
      nextState = Object.assign(nextState, {
        list: action.data,
        paging: action.paging
      })
      break

    case 'ADD_CAR':
      nextState.list = [action.data].concat(nextState.list)
      nextState.redirect = '/'
      break

    case 'SET_CAR':
      nextState.car = action.data
      break

    case 'UPDATE_CAR':
      nextState.list = nextState.list.map(item => {
        if (item.id === action.data.id) {
          item = { ...action.data }
        }
        return item
      })
      nextState.redirect = '/'
      break

    case 'DELETE_CAR':
      nextState.list = nextState.list.filter(item => {
        return item.id !== action.id
      })
      break

    default:
      nextState = state
      break
  }

  return nextState
}
