const initialState = {
  list: [],
  user: null,
  edit_user: null,
  sending: false,
  redirect: null
}

export default (state = initialState, action) => {
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case 'BEFORE_SEND':
      nextState = Object.assign(nextState, {
        sending: true
      })
      break

    case 'AFTER_RESPONSE':
      nextState = Object.assign(nextState, {
        sending: false
      })
      break

    case 'SET_USER':
      nextState = Object.assign(nextState, {
        user: action.data,
        checked: true
      })
      break

    case 'SET_USERS':
      nextState.list = action.data
      nextState.paging = action.paging
      nextState.redirect = null
      break

    case 'ADD_USER':
      nextState.list = [action.data].concat(nextState.list)
      nextState.redirect = '/usuarios'
      break

    case 'UPDATE_USER':
      nextState.list = nextState.list.map(item => {
        if (+item.id === +action.data.id) {
          item = { ...action.data }
        }
        return item
      })
      nextState.redirect = '/usuarios'
      break

    case 'DELETE_USER':
      nextState.list = nextState.list.filter(item => {
        return +item.id !== +action.id
      })
      break

    case 'SET_EDIT_USER':
      nextState.edit_user = action.data
      break

    default:
      nextState = state
      break
  }

  return nextState
}
