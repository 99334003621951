import React, { Component } from 'react'
import Logo from './Logo'
import { Link } from 'react-router-dom'
import { links } from '../utils'

export default class Header extends Component {
  static defaultProps = {
    user: null
  };

  render () {
    return (
      <header className="main-header">
        <div className="main-header__wrapper">
          <Link to={links.get('home')}>
            <Logo />
          </Link>
          {this.props.user &&
            <nav className="main-header__nav">
              <ul>
                <li>
                  <Link to={links.get('home')}>Carros</Link>
                </li>
                <li>
                  <Link to={links.get('banners')}>Banners</Link>
                </li>
                <li>
                  <Link to={links.get('users')}>Usuários</Link>
                </li>
                <li>
                  <Link to={links.get('logout')}>Sair</Link>
                </li>
              </ul>
            </nav>
          }
        </div>
      </header>
    )
  };
}
