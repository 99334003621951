import { Dashboard } from '../components/Dashboard'
import { Login } from '../components/Login'
import { Logout } from '../components/Logout'
import { CarAdd } from '../components/CarAdd'
import { CarEdit } from '../components/CarEdit'
import {
  BannersList,
  BannerAdd,
  BannerEdit
} from '../components/Banners'
import {
  UsersList,
  UserAdd,
  UserEdit
} from '../components/Users'

export default {
  routes: [{
    name: 'home',
    path: '/',
    component: Dashboard,
    requiresAuth: true
  }, {
    name: 'login',
    path: '/login',
    component: Login
  }, {
    name: 'logout',
    path: '/logout',
    component: Logout
  }, {
    name: 'addCar',
    path: '/carros/adicionar',
    component: CarAdd,
    requiresAuth: true
  }, {
    name: 'editCar',
    path: '/carros/editar/:id',
    component: CarEdit,
    requiresAuth: true
  }, {
    name: 'banners',
    path: '/banners',
    component: BannersList,
    requiresAuth: true
  }, {
    name: 'addBanner',
    path: '/banners/adicionar',
    component: BannerAdd,
    requiresAuth: true
  }, {
    name: 'editBanner',
    path: '/banners/editar/:id',
    component: BannerEdit,
    requiresAuth: true
  }, {
    name: 'users',
    path: '/usuarios',
    component: UsersList,
    requiresAuth: true
  }, {
    name: 'addUser',
    path: '/usuarios/adicionar',
    component: UserAdd,
    requiresAuth: true
  }, {
    name: 'editUser',
    path: '/usuarios/editar/:id',
    component: UserEdit,
    requiresAuth: true
  }]
}
