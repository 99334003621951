import React from 'react'

const Thumb = props => {
  const file = props.file
  let isImage = true
  if (file.name) {
    isImage = !file.name.toString().endsWith('mp4')
  }
  return (
    <div
      className="photo-upload-thumb"
      data-id={file.sort}
    >
      <div className="photo-upload-thumb__item">
        {isImage &&
          <img
            src={file.preview}
            className="photo-upload-thumb__item__image"
            alt={file.name}
          />
        }
        {!isImage &&
          <video
            className="photo-upload-thumb__item__image"
            controls
          >
            <source type="video/mp4" src={file.preview} />
          </video>
        }
      </div>
      {props.onDelete &&
        <span
          className="photo-upload-thumb__remove"
          onClick={() => {
            if (window.confirm('Tem certeza?')) {
              props.onDelete(file)
            }
          }}
        >Remover</span>
      }
    </div>
  )
}

export default Thumb
