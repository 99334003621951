import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { AppLayout } from './Layouts'
import Spinner from './Spinner'
import routes from '../routes'
import { checkLogin } from '../actions/user'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    rest.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
  )} />
)

class App extends Component {
  componentDidMount () {
    const { checkLogin, cookies } = this.props
    const token = cookies.get('token')
    checkLogin(token)
  };

  render () {
    const { checked, user } = this.props
    const isAuthenticated = user !== null

    if (!checked) {
      return (
        <div className="splash">
          <Spinner />
        </div>
      )
    }

    return (
      <BrowserRouter>
        <Switch>
          <AppLayout>
            {routes.routes.map(route => {
              if (route.requiresAuth) {
                return (
                  <PrivateRoute
                    isAuthenticated={isAuthenticated}
                    exact={true}
                    key={route.name}
                    path={route.path}
                    component={route.component}
                  />
                )
              } else {
                return (
                  <Route
                    exact={true}
                    key={route.name}
                    path={route.path}
                    component={route.component}
                  />
                )
              }
            })}
          </AppLayout>
        </Switch>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = (state) => {
  return { ...state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkLogin: token => dispatch(checkLogin(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withCookies(App)
)
