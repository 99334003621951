import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { translate } from '../locales'
import Thumb from './Thumb'
import ReactSortable from 'react-sortablejs'

function PhotoUpload(props) {
  const [files, setFiles] = useState([])
  const maxFiles = 10

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  const uniqueID = () => {
    return '_' + Math.random().toString(36).substr(2, 9)
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      const newFiles = acceptedFiles.map((file, index) => {
        const item = {
          code: uniqueID(),
          id: file.id || null,
          preview: file.preview || URL.createObjectURL(file),
          name: file.name,
          path: file.path,
          sort: index,
          file
        }
        return item
      })
      const promises = newFiles.map((item, index) => {
        return getBase64(item.file).then(name => {
          return {
            ...item,
            name
          }
        })
      })
      Promise.all(promises).then(result => {
        let filesSaves = files.filter(item => item.id && !item.remove)
          .concat(result)
          .splice(0, maxFiles)
        filesSaves = filesSaves.map((item, index) => {
          return {
            ...item,
            sort: index
          }
        })
        setFiles(filesSaves)
        props.onChange(filesSaves)
      })
    }
  });

  const { editCar } = props
  if (
    editCar &&
    editCar.id &&
    editCar.images &&
    editCar.images.length > 0 &&
    files.length === 0
  ) {
    setFiles(
      editCar.images.map((item, index) => {
        return {
          code: uniqueID(),
          id: item.id,
          preview: item.thumb_url,
          name: item.id,
          sort: index
        }
      })
    )
  }

  const thumbs = files
    .filter(file => !file.remove)
    .map((file, index) => (
      <Thumb
        key={file.code}
        file={file}
        onDelete={file => {
          setFiles(files.map(item => {
            if (item.preview === file.preview) {
              item.remove = true
            }
            return item
          }))
          props.onDelete(file)
        }}
      />
    ))

  const sortThumbs = order => {
    let filesOrdered = []
    order.forEach((value, index) => {
      const item = files.find(item => +item.sort === +value)
      filesOrdered.push(item)
    })
    const nfilesOrdered = filesOrdered.map((item, index) => {
      return {
        ...item,
        sort: index
      }
    })
    setFiles(nfilesOrdered)
    props.onChange(nfilesOrdered)
  };

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files]);

  return (
    <div className="form__control">
      <section className="photo-upload">
        <div {...getRootProps({ className: 'photo-upload__dropzone' })}>
          <input {...getInputProps()} />
          <h2>{translate('actions.dropFiles')}</h2>
          <p>{translate('actions.orClickToChoose')}</p>
        </div>
        {thumbs.length > 0 &&
          <div>
            <ReactSortable
              className="photo-upload__thumbs"
              tag="div"
              onChange={sortThumbs}
            >
              {thumbs}
            </ReactSortable>
          </div>
        }
      </section>
      <div className="form__help">As imagens devem ter <strong>550x420</strong> no formato <strong>jpg</strong></div>
    </div>
  );
}

export default PhotoUpload
