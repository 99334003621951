import React, { Component } from 'react'
import FormControl from './FormControl'
import ButtonSpinner from './ButtonSpinner'
import CarSpec from './CarSpec'
import SelectManage from './SelectManage'
import PhotoUpload from './PhotoUpload'
import { form, date, number } from '../utils'
import { translate } from '../locales'

class CarForm extends Component {
  static defaultProps = {
    editCar: {},
    errors: {},
    sending: false
  };


  constructor (props) {
    super(props)
    let state = {
      model: {
        brand_id: '',
        model_id: '',
        version_id: '',
        year_id: '',
        fuel_id: '',
        gear_id: '',
        color_id: '',
        km: '',
        ports: '',
        registration_plate: '',
        price: '',
        description: '',
        status: true,
        highlighted: false,
        sold: false,
        sold_date: '',
        car_optionals: [],
        car_images: []
      }
    }
    const { editCar } = props
    if (editCar && editCar.id) {
      let soldDate = null
      if (editCar.sold_date) {
        soldDate = date.formatDateOnly(editCar.sold_date)
      }
      state.model = {
        ...state.model,
        ...editCar,
        car_images: editCar.images,
        car_optionals: editCar.optionals.map(item => +item.id),
        sold_date: soldDate,
        price: number.formatMoney(editCar.price)
      }
    }
    this.state = state
  };

  render () {
    const { errors, editCar, sending } = this.props
    const { model } = this.state

    return (
      <div className="car-form">
        <PhotoUpload
          editCar={editCar}
          onChange={this._onChangePhotos}
          onDelete={this._onDeletePhoto}
        />
        <CarSpec
          editCar={editCar}
          onChange={this._onChangeCarSpec}
        />
        <div className="form__control form__control--error">
          {this._getCarSpecErrors()}
        </div>
        <div className="row row--medium-3">
          <SelectManage
            selected={model.fuel_id}
            label={translate('car.fuel')}
            modelName='fuel'
            onChange={value => this._onChangeItem('fuel_id', +value)}
          />
          <SelectManage
            selected={model.gear_id}
            label={translate('car.gear')}
            modelName='gear'
            onChange={value => this._onChangeItem('gear_id', +value)}
          />
          <SelectManage
            selected={model.color_id}
            label={translate('car.color')}
            modelName='color'
            onChange={value => this._onChangeItem('color_id', +value)}
          />
        </div>
        <SelectManage
          selected={model.car_optionals}
          multiple={true}
          label={translate('car.optionals')}
          modelName='optional'
          onChange={value => this._onChangeItem('car_optionals', value)}
        />
        <div className="row row--medium-4">
          <FormControl
            maxLength='7'
            value={model.km}
            errors={form.getErrors('km', errors)}
            label={translate('car.km')}
            name='km'
            onChange={this._onChange}
          />
          <FormControl
            mask='ports'
            value={model.ports}
            errors={form.getErrors('ports', errors)}
            label={translate('car.ports')}
            name='ports'
            onChange={this._onChange}
          />
          <FormControl
            value={model.registration_plate}
            errors={form.getErrors('registration_plate', errors)}
            label={translate('car.registration_plate')}
            name='registration_plate'
            onChange={this._onChange}
          />
          <FormControl
            mask='money'
            value={model.price}
            errors={form.getErrors('price', errors)}
            label={translate('car.price')}
            name='price'
            onChange={this._onChange}
          />
        </div>
        <FormControl
          value={model.description}
          errors={form.getErrors('description', errors)}
          label={translate('car.description')}
          name='description'
          onChange={this._onChange}
          maxLength='400'
        />
        <div className="row row--medium-6">
          <FormControl
            value={model.status}
            errors={form.getErrors('status', errors)}
            label={translate('car.status')}
            name='status'
            type='checkbox'
            onChange={this._onChange}
          />
          <FormControl
            value={model.highlighted}
            errors={form.getErrors('highlighted', errors)}
            label={translate('car.highlighted')}
            name='highlighted'
            type='checkbox'
            onChange={this._onChange}
          />
          <div>
            <FormControl
              value={model.sold}
              errors={form.getErrors('sold', errors)}
              label={translate('car.sold')}
              name='sold'
              type='checkbox'
              onChange={this._onChange}
            />
            {model.sold &&
              <FormControl
                mask='date'
                value={model.sold_date}
                errors={form.getErrors('sold_date', errors)}
                label={translate('car.sold_date')}
                name='sold_date'
                onChange={this._onChange}
              />
            }
          </div>
        </div>
        <div className="form__buttons">
          <ButtonSpinner
            onClick={this._handleSubmit}
            disabled={sending}
            type='button'
          >
            {translate('actions.save')}
          </ButtonSpinner>
        </div>
      </div>
    )
  };

  _onChange = (name, value) => {
    const model = {
      ...this.state.model,
      [name]: value
    }
    this.setState({ model })
  };

  _handleSubmit = event => {
    event.preventDefault()
    let data = { ...this.state.model }
    data.km = data.km.toString().replace(/\./gi, '')
    data.ports = +data.ports
    data.price = number.moneyToDecimal(data.price)
    if (data.sold_date) {
      data.sold_date = date.toDateOnly(data.sold_date)
    }
    this.props.onSubmit(data)
  };

  _onChangeCarSpec = data => {
    let model = {
      ...this.state.model,
      ...data
    }
    this.setState({ model })
  };

  _onChangeItem = (field, value) => {
    this._onChange(field, value)
  };

  _getCarSpecErrors = () => {
    let errors = []
    const items = ['brand_id', 'model_id', 'version_id', 'year_id']
    items.forEach(item => {
      if (this.props.errors[item]) {
        errors = errors.concat(this.props.errors[item])
      }
    })
    if (errors.length > 0) {
      const errorItems = errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))
      return <ul className="form__control__errors">{errorItems}</ul>
    }
  };

  _onChangePhotos = photos => {
    const model = {
      ...this.state.model,
      car_images: photos
    }
    this.setState({ model })
  };

  _onDeletePhoto = photo => {
    if (photo.id) {
      const photos = this.state.model.car_images.map(item => {
        if (+item.id === +photo.id) {
          item.remove = true
        }
        return item
      })
      this._onChangePhotos(photos)
    }
  };
}

export default CarForm
