import { api } from '../utils'
import { notificate } from './notification'
import { beforeSend, afterResponse } from './'
import { loadCars } from './car'

const identifier = 'sync_car'

export const syncCars = (data, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.post('admin/sync-cars', data, token)
      .then(result => {
        const type = result.success ? 'info' : 'error'
        dispatch(notificate(result.message, type))
        dispatch(afterResponse(identifier))
        if (result.success) {
          dispatch(loadCars({}, token))
        }
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
