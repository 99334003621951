import React, { Component } from 'react'
import FormControl from './FormControl'
import ButtonSpinner from './ButtonSpinner'
import Thumb from './Thumb'
import { form } from '../utils'
import { translate } from '../locales'

class BannerForm extends Component {
  static defaultProps = {
    editBanner: {},
    errors: {},
    sending: false
  };

  constructor (props) {
    super(props)
    let state = {
      model: {
        file: '',
        link: '',
        name: '',
        local: 'home',
        sort: 0,
        status: 1
      }
    }
    const { editBanner } = props
    if (editBanner && editBanner.id) {
      state.model = {
        ...state.model,
        ...editBanner
      }
    }
    this.state = state
  };

  render () {
    const { errors, sending } = this.props
    const { model } = this.state

    return (
      <form className="banner-form" onSubmit={this._handleSubmit}>
        <FormControl
          value={model.file}
          errors={form.getErrors('file', errors)}
          label={translate('banner.file')}
          help={translate('banner.fileHelp')}
          name='file'
          type='file'
          onChange={this._onChange}
        />
        {this._getCurrentFile()}
        <div className="row row--medium-2">
          <FormControl
            value={model.name}
            errors={form.getErrors('name', errors)}
            label={translate('banner.name')}
            name='name'
            onChange={this._onChange}
          />
          <FormControl
            value={model.link}
            errors={form.getErrors('link', errors)}
            label={translate('banner.link')}
            name='link'
            onChange={this._onChange}
          />
        </div>
        <div className="row row--medium-2">
          <FormControl
            value={model.local}
            options={this._getLocalOptions()}
            errors={form.getErrors('local', errors)}
            label={translate('banner.local')}
            name='local'
            type='radio'
            onChange={this._onChange}
          />
          <FormControl
            type='checkbox'
            value={model.status}
            errors={form.getErrors('status', errors)}
            label={translate('banner.status')}
            name='status'
            onChange={this._onChange}
          />
        </div>
        <div className="form__buttons">
          <ButtonSpinner
            disabled={sending}
            type='submit'
          >
            {translate('actions.save')}
          </ButtonSpinner>
        </div>
      </form>
    )
  };

  _onChange = (name, value) => {
    const model = {
      ...this.state.model,
      [name]: value
    }
    this.setState({ model })
  };

  _handleSubmit = event => {
    event.preventDefault()
    const data = { ...this.state.model }
    this.props.onSubmit(data)
  };

  _getCurrentFile = () => {
    const { editBanner } = this.props
    const { model } = this.state
    if (editBanner && editBanner.id && model.file === '') {
      const file = {
        preview: editBanner.file_url,
        name: editBanner.file_url
      }
      return (
        <div className="row row--medium-4">
          <Thumb file={file} />
        </div>
      )
    }
  };

  _getLocalOptions = () => {
    return [{
      value: 'home',
      label: 'Home'
    }, {
      value: 'car',
      label: 'Detalhe do carro'
    }]
  };
}

export default BannerForm
