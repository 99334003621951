import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createUser } from '../../actions/user'
import { notificate } from '../../actions/notification'
import { UserForm } from '../'
import { userValidator } from '../../validators'
import { links } from '../../utils'

class UserAdd extends Component {
  state = {
    errors: {}
  };

  componentDidUpdate () {
    const { redirect } = this.props.user
    if (redirect) {
      return this.props.history.push(redirect)
    }
  };

  render () {
    const { errors } = this.state
    const { sending } = this.props.user

    return (
      <div className="main-content">
        <header className="main-content__header">
          <h1 className="main-content__title">Adicionar usuário</h1>
          <Link to={links.get('users')} className="main-content__cancel">Voltar</Link>
        </header>
        <UserForm
          sending={sending}
          errors={errors}
          onSubmit={this._handleSubmit}
        />
      </div>
    )
  };

  _handleSubmit = data => {
    const errors = {}
    this.setState({ errors }, () => {
      userValidator.create(data).then(result => {
        if (result.success) {
          const { token } = this.props
          this.props.createUser(data, token)
        } else {
          this.setState({ errors: result.errors })
        }
      }).catch(error => {
        this.props.notificate(error.message)
      })
    })
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.user.user.token,
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (data, token) => dispatch(createUser(data, token)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdd)
