import { api } from '../utils'
import { notificate } from './notification'
import { beforeSend, afterResponse } from './'

const identifier = 'fuel'

export const loadFuels = token => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.get('admin/fuels', {}, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'SET_FUELS',
            data: result.data
          })
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createFuel = (data, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.post('admin/fuels', data, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'SET_FUEL',
            data: result.data
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateFuel = (data, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.put(`admin/fuels/${data.id}`, data, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'UPDATE_FUEL',
            data: result.data
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const deleteFuel = (id, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.delete(`admin/fuels/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'DELETE_FUEL',
            id
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
