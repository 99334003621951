import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  loadBrands,
  createBrand,
  updateBrand,
  deleteBrand
} from '../actions/brand'
import {
  loadModels,
  createModel,
  updateModel,
  deleteModel,
  clearModels
} from '../actions/model'
import {
  loadVersions,
  createVersion,
  updateVersion,
  deleteVersion,
  clearVersions
} from '../actions/version'
import {
  loadYears,
  createYear,
  updateYear,
  deleteYear,
  clearYears
} from '../actions/year'
import Spinner from './Spinner'
import { translate } from '../locales'
import { RIEInput } from 'riek'
import closeIcon from '../assets/svg/close.svg'
import arrowRightIcon from '../assets/svg/arrow-right.svg'

class CarSpec extends Component {
  static defaultProps = {
    editCar: {}
  };

  state = {
    selected: {
      brand_id: '',
      model_id: '',
      version_id: '',
      year_id: ''
    },
    brand: '',
    model: '',
    version: '',
    year: ''
  };

  componentDidMount () {
    const { token, editCar } = this.props
    this.props.loadBrands(token)

    if (editCar && editCar.id) {
      this.props.loadModels(editCar.brand_id, token)
      this.props.loadVersions(editCar.model_id, token)
      this.props.loadYears(editCar.version_id, token)
      let selected = {
        brand_id: editCar.brand_id,
        model_id: editCar.model_id,
        version_id: editCar.version_id,
        year_id: editCar.year_id
      }
      this.setState({ selected })
    } else {
      this.props.clearModels()
      this.props.clearVersions()
      this.props.clearYears()
    }
  }

  render () {
    return (
      <div className="select-step">
        <div className="select-step__item">
          <h2 className="select-step__item__title">
            {translate('car.brands')}
          </h2>
          <ul className="select-step__item__list">
            {this._getBrandItems()}
          </ul>
          <div className="select-step__item__form">
            <div className="select-step__item__form__wrapper">
              <label>{translate('car.newItem')}</label>
              <input
                ref={ref => this.brandInput = ref}
                value={this.state.brand}
                onChange={this._onChange}
                name='brand'
                onKeyDown={event => this._handleOnKeyDown('_createBrand', event)}
              />
              <button
                type="button"
                onClick={this._createBrand}
              >+</button>
            </div>
          </div>
        </div>
        <div className="select-step__item">
          <h2 className="select-step__item__title">
            {translate('car.models')}
          </h2>
          <ul className="select-step__item__list">
            {this._getModelItems()}
          </ul>
          <div className="select-step__item__form">
            <div className="select-step__item__form__wrapper">
              <label>{translate('car.newItem')}</label>
              <input
                ref={ref => this.modelInput = ref}
                value={this.state.model}
                onChange={this._onChange}
                name='model'
                onKeyDown={event => this._handleOnKeyDown('_createModel', event)}
              />
              <button
                type="button"
                onClick={this._createModel}
              >+</button>
            </div>
          </div>
        </div>
        <div className="select-step__item">
          <h2 className="select-step__item__title">
            {translate('car.versions')}
          </h2>
          <ul className="select-step__item__list">
            {this._getVersionItems()}
          </ul>
          <div className="select-step__item__form">
            <div className="select-step__item__form__wrapper">
              <label>{translate('car.newItem')}</label>
              <input
                ref={ref => this.versionInput = ref}
                value={this.state.version}
                onChange={this._onChange}
                name='version'
                onKeyDown={event => this._handleOnKeyDown('_createVersion', event)}
              />
              <button
                type="button"
                onClick={this._createVersion}
              >+</button>
            </div>
          </div>
        </div>
        <div className="select-step__item">
          <h2 className="select-step__item__title">
            {translate('car.years')}
          </h2>
          <ul className="select-step__item__list">
            {this._getYearItems()}
          </ul>
          <div className="select-step__item__form">
            <div className="select-step__item__form__wrapper">
              <label>{translate('car.newItem')}</label>
              <input
                ref={ref => this.yearInput = ref}
                value={this.state.year}
                onChange={this._onChange}
                name='year'
                onKeyDown={event => this._handleOnKeyDown('_createYear', event)}
              />
              <button
                type="button"
                onClick={this._createYear}
              >+</button>
            </div>
          </div>
        </div>
      </div>
    )
  };

  _onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  };

  _onEditInPlace = (fn, item, value) => {
    const data = {
      ...item,
      name: value.name
    }
    const { token } = this.props
    this.props[fn](data, token)
  };

  _createBrand = () => {
    const data = { name: this.state.brand }
    if (data.name) {
      const { token } = this.props
      this.props.createBrand(data, token)
      this.setState({ brand: '' })
      this.brandInput.focus()
    }
  };

  _createModel = () => {
    const data = {
      brand_id: this.state.selected.brand_id,
      name: this.state.model
    }
    if (data.name) {
      const { token } = this.props
      this.props.createModel(data, token)
      this.setState({ model: '' })
      this.modelInput.focus()
    }
  };

  _createVersion = () => {
    const data = {
      brand_id: this.state.selected.brand_id,
      model_id: this.state.selected.model_id,
      name: this.state.version
    }
    if (data.name) {
      const { token } = this.props
      this.props.createVersion(data, token)
      this.setState({ version: '' })
      this.versionInput.focus()
    }
  };

  _createYear = () => {
    const data = {
      brand_id: this.state.selected.brand_id,
      model_id: this.state.selected.model_id,
      version_id: this.state.selected.version_id,
      name: this.state.year
    }
    if (data.name) {
      const { token } = this.props
      this.props.createYear(data, token)
      this.setState({ year: '' })
      this.yearInput.focus()
    }
  };

  _getBrandItems = () => {
    return this._getItems('brand')
  };

  _getModelItems = () => {
    return this._getItems('model')
  };

  _getVersionItems = () => {
    return this._getItems('version')
  };

  _getYearItems = () => {
    return this._getItems('year')
  };

  _getItems = name => {
    const mapItems = {
      brand: {
        sending: this.props.brand.sending,
        list: this.props.brand.list,
        selected: this.state.selected.brand_id,
        onSelect: this._selectBrand,
        onUpdate: 'updateBrand',
        onDelete: 'deleteBrand'
      },
      model: {
        sending: this.props.model.sending,
        list: this.props.model.list,
        selected: this.state.selected.model_id,
        onSelect: this._selectModel,
        onUpdate: 'updateModel',
        onDelete: 'deleteModel'
      },
      version: {
        sending: this.props.version.sending,
        list: this.props.version.list,
        selected: this.state.selected.version_id,
        onSelect: this._selectVersion,
        onUpdate: 'updateVersion',
        onDelete: 'deleteVersion'
      },
      year: {
        sending: this.props.year.sending,
        list: this.props.year.list,
        selected: this.state.selected.year_id,
        onSelect: this._selectYear,
        onUpdate: 'updateYear',
        onDelete: 'deleteYear'
      }
    }
    const config = mapItems[name]
    const sending = config.sending
    if (sending) {
      return (
        <div className="select-step__item__spinner">
          <Spinner />
        </div>
      )
    } else {
      return config.list.map(item => {
        let className = null
        if (item.id === config.selected) {
          className = 'selected'
        }
        return (
          <li
            className={className}
            key={item.id}
          >
            <RIEInput
              className="edit-in-place"
              value={item.name}
              propName='name'
              validate={value => value !== ''}
              change={value => this._onEditInPlace(config.onUpdate, item, value)}
            />
            <span
              className="select-step__item__list__select"
              onClick={() => config.onSelect(item)}
            >
              <img
                src={arrowRightIcon}
                alt='Select'
              />
            </span>
            <span
              className="select-step__item__list__delete"
              onClick={() => this._confirmDelete(config.onDelete, item)}
            >
              <img
                src={closeIcon}
                alt='Delete'
              />
            </span>
          </li>
        )
      })
    }
  };

  _selectBrand = item => {
    const selected = {
      ...this.state.selected,
      brand_id: item.id,
      model_id: '',
      version_id: '',
      year_id: ''
    }
    this.props.clearModels()
    this.props.clearVersions()
    this.props.clearYears()
    this.setState({ selected }, () => {
      const { token } = this.props
      this.props.loadModels(item.id, token)
      this.props.onChange(selected)
    })
  };

  _selectModel = item => {
    const selected = {
      ...this.state.selected,
      model_id: item.id,
      version_id: '',
      year_id: ''
    }
    this.props.clearVersions()
    this.props.clearYears()
    this.setState({ selected }, () => {
      const { token } = this.props
      this.props.loadVersions(item.id, token)
      this.props.onChange(selected)
    })
  };

  _selectVersion = item => {
    const selected = {
      ...this.state.selected,
      version_id: item.id,
      year_id: ''
    }
    this.props.clearYears()
    this.setState({ selected }, () => {
      const { token } = this.props
      this.props.loadYears(item.id, token)
      this.props.onChange(selected)
    })
  };

  _selectYear = item => {
    const selected = {
      ...this.state.selected,
      year_id: item.id
    }
    this.setState({ selected }, () => {
      this.props.onChange(selected)
    })
  };

  _handleOnKeyDown = (name, event) => {
    if (event.key === 'Enter') {
      this[name]()
    }
  };

  _confirmDelete = (name, item) => {
    if (window.confirm(translate('actions.areYouSure'))) {
      const { token } = this.props
      let selected = { ...this.state.selected }
      this.props[name](item.id, token)
      switch (name) {
        case 'deleteBrand':
          this.props.clearModels()
          this.props.clearVersions()
          this.props.clearYears()
          selected.brand_id = ''
          selected.model_id = ''
          selected.version_id = ''
          selected.year_id = ''
          this.setState({ selected })
          break

        case 'deleteModel':
          this.props.clearVersions()
          this.props.clearYears()
          selected.model_id = ''
          selected.version_id = ''
          selected.year_id = ''
          this.setState({ selected })
          break

        case 'deleteVersion':
          this.props.clearYears()
          selected.version_id = ''
          selected.year_id = ''
          this.setState({ selected })
          break

        case 'deleteYear':
          selected.year_id = ''
          this.setState({ selected })
          break

        default:
          break
      }
    }
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.user.user.token,
    brand: state.brand,
    model: state.model,
    version: state.version,
    year: state.year
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadBrands: token => dispatch(loadBrands(token)),
    createBrand: (data, token) => dispatch(createBrand(data, token)),
    updateBrand: (data, token) => dispatch(updateBrand(data, token)),
    deleteBrand: (id, token) => dispatch(deleteBrand(id, token)),

    loadModels: (brandId, token) => dispatch(loadModels(brandId, token)),
    createModel: (data, token) => dispatch(createModel(data, token)),
    updateModel: (data, token) => dispatch(updateModel(data, token)),
    deleteModel: (id, token) => dispatch(deleteModel(id, token)),
    clearModels: () => dispatch(clearModels()),

    loadVersions: (modelId, token) => dispatch(loadVersions(modelId, token)),
    createVersion: (data, token) => dispatch(createVersion(data, token)),
    updateVersion: (data, token) => dispatch(updateVersion(data, token)),
    deleteVersion: (id, token) => dispatch(deleteVersion(id, token)),
    clearVersions: () => dispatch(clearVersions()),

    loadYears: (versionId, token) => dispatch(loadYears(versionId, token)),
    createYear: (data, token) => dispatch(createYear(data, token)),
    updateYear: (data, token) => dispatch(updateYear(data, token)),
    deleteYear: (id, token) => dispatch(deleteYear(id, token)),
    clearYears: () => dispatch(clearYears()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarSpec)
