import { api } from '../utils'
import { notificate } from './notification'
import { beforeSend, afterResponse } from './'

const identifier = 'banner'

export const loadBanners = (filters = {}, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.get('admin/banners', filters, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'SET_BANNERS',
            data: result.data,
            paging: result.paging
          })
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createBanner = (data, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.post('admin/banners', data, token)
      .then(result => {
        dispatch(afterResponse(identifier))
        if (result.success) {
          dispatch({
            type: 'ADD_BANNER',
            data: result.data
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadBanner = (id, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.get(`admin/banners/${id}`, {}, token)
      .then(result => {
        dispatch(afterResponse(identifier))
        if (result.success) {
          dispatch({
            type: 'SET_BANNER',
            data: result.data
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateBanner = (data, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.put(`admin/banners/${data.id}`, data, token)
      .then(result => {
        dispatch(afterResponse(identifier))
        if (result.success) {
          dispatch({
            type: 'UPDATE_BANNER',
            data: result.data
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const deleteBanner = (id, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.delete(`admin/banners/${id}`, {}, token)
      .then(result => {
        dispatch(afterResponse(identifier))
        if (result.success) {
          dispatch({
            type: 'DELETE_BANNER',
            id
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const clearBanner = () => {
  return dispatch => {
    dispatch({
      type: 'SET_BANNER',
      data: {}
    })
  }
}
