import { api } from '../utils'
import { notificate } from './notification'
import { beforeSend, afterResponse } from './'

const identifier = 'version'

export const loadVersions = (modelId, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    const params = { model_id: modelId }
    api.get('admin/versions', params, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'SET_VERSIONS',
            data: result.data
          })
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createVersion = (data, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.post('admin/versions', data, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'SET_VERSION',
            data: result.data
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateVersion = (data, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.put(`admin/versions/${data.id}`, data, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'UPDATE_VERSION',
            data: result.data
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const deleteVersion = (id, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.delete(`admin/versions/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'DELETE_VERSION',
            id
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const clearVersions = () => {
  return dispatch => {
    dispatch({
      type: 'SET_VERSIONS',
      data: []
    })
  }
}
