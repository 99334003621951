import React, { Component } from 'react'
import { links } from '../utils'
import { Link } from 'react-router-dom'

class User extends Component {
  static defaultProps = {
    user: {},
    onDelete: () => {}
  };

  render () {
    const { user } = this.props
    return (
      <div
        className="user"
      >
        <div className="user__data">
          <div className="user__info">
            <div className="user__info__name">{user.name}</div>
            <div className="user__info__email">{user.email}</div>
          </div>
        </div>
        <div className="user__actions">
          <Link to={links.get('editUser', { id: user.id })} className="user__actions__link user__actions__link--edit">Editar</Link>
          <span className="user__actions__link user__actions__link--delete" onClick={this._confirmDelete}>Deletar</span>
        </div>
      </div>
    )
  };

  _confirmDelete = event => {
    event.preventDefault()
    if (window.confirm('Tem certeza?')) {
      this.props.onDelete()
    }
  };
}

export default User
