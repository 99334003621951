import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import { AccountLinks, FormControl, ButtonSpinner } from '../'
import { translate } from '../../locales'
import { form } from '../../utils'
import { login } from '../../actions/user'
import { notificate } from '../../actions/notification'
import { userValidator } from '../../validators'

class Login extends Component {
  state = {
    errors: {},
    model: {
      email: '',
      password: ''
    }
  };

  componentDidUpdate (prevProps) {
    const { user } = this.props
    if (user) {
      this.props.cookies.set('token', user.token, {
        path: '/'
      })
      this.props.history.push('/')
    }
  };

  render () {
    const { errors, model } = this.state
    const { sending } = this.props

    return (
      <div className="login-page">
        <span></span>
        <div className="login">
          <h1 className="page-title">{translate('titles.login')}</h1>
          <form className="form" action="#" onSubmit={this._handleSubmit}>
            <FormControl
              errors={form.getErrors('email', errors)}
              label={translate('user.email')}
              name='email'
              type='email'
              value={model.email}
              onChange={this._onChange}
            />
            <FormControl
              errors={form.getErrors('password', errors)}
              label={translate('user.password')}
              name='password'
              type='password'
              value={model.password}
              onChange={this._onChange}
            />
            <div className="form__buttons">
              <ButtonSpinner
                block={true}
                disabled={sending}
                type='submit'
              >
                {translate('actions.enter')}
              </ButtonSpinner>
            </div>
          </form>
        </div>
        <AccountLinks />
      </div>
    )
  };

  _onChange = (name, value) => {
    const model = {
      ...this.state.model,
      [name]: value
    }
    this.setState({ model })
  };

  _handleSubmit = event => {
    event.preventDefault()
    this.setState({ errors: {} }, () => {
      const data = Object.assign({}, this.state.model)
      userValidator.login(data).then(result => {
        if (result.success) {
          this.props.login(data)
        } else {
          this.setState({ errors: result.errors })
          this.props.notificate(result.message)
        }
      }).catch(error => {
        this.props.notificate(error.message)
      })
    })
  };
}

const mapStateToProps = (state) => {
  return { ...state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: data => dispatch(login(data)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withCookies(Login)
)
