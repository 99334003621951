import v8n from 'v8n'
import validatorUtils from './validation_utils'

export default {
  create (data) {
    return v8n()
      .schema({
        file: v8n().not.null().minLength(1),
        link: v8n().not.null().minLength(1),
        name: v8n().not.null().minLength(1),
        local: v8n().not.null().minLength(1),
        sort: v8n().not.null().number()
      })
      .testAsync(data)
      .then(result => ({ success: true }))
      .catch(e => validatorUtils.buildErrors('banners', e))
  },
  update (data) {
    return v8n()
      .schema({
        link: v8n().not.null().minLength(1),
        name: v8n().not.null().minLength(1),
        local: v8n().not.null().minLength(1),
        sort: v8n().not.null().number()
      })
      .testAsync(data)
      .then(result => ({ success: true }))
      .catch(e => validatorUtils.buildErrors('banners', e))
  }
}
