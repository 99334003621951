import React, { Component } from 'react'
import { connect } from 'react-redux'
import close from '../assets/svg/close.svg'
import { closeNotification } from '../actions/notification'

class Notification extends Component {
  state = {
    closing: false
  }

  constructor (props) {
    super(props)
    this.closeNotification = this.closeNotification.bind(this)
  }

  closeNotification () {
    this.setState({ closing: true })
    setTimeout(() => {
      this.props.closeNotification()
      this.setState({ closing: false })
    }, 500)
  }

  classes () {
    const { message, messageType } = this.props
    let items = ['notification']
    if (message && !this.state.closing) {
      items.push('notification--active')
    }
    if (messageType === 'error') {
      items.push('notification--error')
    }
    return items.join(' ')
  }

  render() {
    const { message } = this.props

    return (
      <div className={this.classes()}>
        <button onClick={this.closeNotification}>
          <img src={close} alt='close' />
        </button>
        <p>{message}</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.notification }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeNotification: () => dispatch(closeNotification())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
