import React, { Component } from 'react'
import { links, date } from '../utils'
import { Link } from 'react-router-dom'
import Thumb from './Thumb'

class Banner extends Component {
  static defaultProps = {
    banner: {},
    onDelete: () => {}
  };

  render () {
    const { banner } = this.props
    const thumb = this._getThumb()
    return (
      <div
        className="banner"
      >
        <div className="banner__data">
          {thumb}
          <div className="banner__info">
            <div className="banner__info__date">Criado em: {date.format(banner.created_at)}</div>
            <div className="banner__info__name">{banner.name}</div>
            <div className="banner__info__local">Local: {this._getLocal(banner.local)}</div>
            <div className="banner__info__link">Link: {banner.link}</div>
          </div>
        </div>
        <div className="banner__actions">
          <Link to={links.get('editBanner', { id: banner.id })} className="banner__actions__link banner__actions__link--edit">Editar</Link>
          <span className="banner__actions__link banner__actions__link--delete" onClick={this._confirmDelete}>Deletar</span>
        </div>
      </div>
    )
  };

  _confirmDelete = event => {
    event.preventDefault()
    if (window.confirm('Tem certeza?')) {
      this.props.onDelete()
    }
  };

  _getThumb = () => {
    const { banner } = this.props
    const file = {
      name: banner.file_url,
      preview: banner.file_url
    }
    return (
      <div className="banner__thumb">
        <Thumb key={banner.id} file={file} />
      </div>
    )
  };

  _getLocal = value => {
    const items = {
      home: 'Home',
      car: 'Detalhe do carro'
    }
    return items[value]
  };
}

export default Banner
