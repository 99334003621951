import React, { Component } from 'react'
import FormControl from './FormControl'
import ButtonSpinner from './ButtonSpinner'
import { form } from '../utils'
import { translate } from '../locales'

class UserForm extends Component {
  static defaultProps = {
    editUser: {},
    errors: {},
    sending: false
  };

  constructor (props) {
    super(props)
    let state = {
      model: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        status: true
      }
    }
    const { editUser } = props
    if (editUser && editUser.id) {
      state.model = {
        ...state.model,
        ...editUser
      }
    }
    this.state = state
  };

  render () {
    const { errors, sending } = this.props
    const { model } = this.state

    return (
      <form className="user-form" onSubmit={this._handleSubmit}>
        <div className="row row--medium-2">
          <FormControl
            value={model.name}
            errors={form.getErrors('name', errors)}
            label={translate('user.name')}
            name='name'
            onChange={this._onChange}
          />
          <FormControl
            value={model.email}
            errors={form.getErrors('email', errors)}
            label={translate('user.email')}
            name='email'
            type='email'
            onChange={this._onChange}
          />
        </div>
        <div className="row row--medium-2">
          <FormControl
            value={model.password}
            errors={form.getErrors('password', errors)}
            label={translate('user.password')}
            name='password'
            type='password'
            onChange={this._onChange}
          />
          <FormControl
            value={model.password_confirmation}
            errors={form.getErrors('password_confirmation', errors)}
            label={translate('user.password_confirmation')}
            name='password_confirmation'
            type='password'
            onChange={this._onChange}
          />
        </div>
        <FormControl
          value={model.status}
          errors={form.getErrors('status', errors)}
          label={translate('user.status')}
          name='status'
          type='checkbox'
          onChange={this._onChange}
        />
        <div className="form__buttons">
          <ButtonSpinner
            disabled={sending}
            type='submit'
          >
            {translate('actions.save')}
          </ButtonSpinner>
        </div>
      </form>
    )
  };

  _onChange = (name, value) => {
    const model = {
      ...this.state.model,
      [name]: value
    }
    this.setState({ model })
  };

  _handleSubmit = event => {
    event.preventDefault()
    const data = { ...this.state.model }
    this.props.onSubmit(data)
  };
}

export default UserForm
