import v8n from 'v8n'
import isemail from 'isemail'

v8n.extend({
  validEmail: () => {
    return value => isemail.validate(value)
  }
})

v8n.extend({
  validRegistrationPlate: () => {
    return value => {
      const regex = /[a-z]{3}-[0-9]{4}/i
      return regex.test(value)
    }
  }
})
