const initialState = {
  sending: false
}

const identifier = 'sync_car'

export default (state = initialState, action) => {
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case 'BEFORE_SEND':
      if (action.identifier === identifier) {
        nextState = Object.assign(nextState, {
          sending: true
        })
      }
      break

    case 'AFTER_RESPONSE':
      if (action.identifier === identifier) {
        nextState = Object.assign(nextState, {
          sending: false
        })
      }
      break

    default:
      nextState = state
      break
  }

  return nextState
}
