import { api } from '../utils'
import { notificate } from './notification'
import { beforeSend, afterResponse } from './'

const identifier = 'year'

export const loadYears = (versionId, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    const params = { version_id: versionId }
    api.get('admin/years', params, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'SET_YEARS',
            data: result.data
          })
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createYear = (data, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.post('admin/years', data, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'SET_YEAR',
            data: result.data
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateYear = (data, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.put(`admin/years/${data.id}`, data, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'UPDATE_YEAR',
            data: result.data
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const deleteYear = (id, token) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    api.delete(`admin/years/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch({
            type: 'DELETE_YEAR',
            id
          })
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const clearYears = () => {
  return dispatch => {
    dispatch({
      type: 'SET_YEARS',
      data: []
    })
  }
}
