import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RIEInput } from 'riek'
import FormControl from './FormControl'
import ButtonSpinner from './ButtonSpinner'
import { translate } from '../locales'
import configIcon from '../assets/svg/cog.svg'
import closeIcon from '../assets/svg/close.svg'
import {
  loadFuels,
  createFuel,
  updateFuel,
  deleteFuel
} from '../actions/fuel'
import {
  loadGears,
  createGear,
  updateGear,
  deleteGear
} from '../actions/gear'
import {
  loadColors,
  createColor,
  updateColor,
  deleteColor
} from '../actions/color'
import {
  loadOptionals,
  createOptional,
  updateOptional,
  deleteOptional
} from '../actions/optional'

class SelectManage extends Component {
  static defaultProps = {
    label: null,
    modelName: null,
    errors: [],
    onChange: () => {},
    multiple: false,
    selected: null
  };

  state = {
    openedModal: false,
    value: '',
    checkeds: []
  };

  componentDidMount () {
    const { modelName, token, selected, multiple } = this.props
    const map = {
      fuel: 'loadFuels',
      gear: 'loadGears',
      color: 'loadColors',
      optional: 'loadOptionals'
    }
    const fnName = map[modelName]
    this.props[fnName](token)

    if (selected) {
      if (multiple) {
        this.setState({ checkeds: selected })
      } else {
        this.setState({ value: selected })
      }
    }
  };

  render() {
    const { label, multiple } = this.props
    const { openedModal, value } = this.state
    const sending = this._getDisabled()
    return (
      <div className="select-manage">
        <div className={this._getClasses()}>
          <label htmlFor={this.props.name} className="form__label">
            <button onClick={this._openModal}>
              <img src={configIcon} alt="Config" />
            </button>
            <span>{this.props.label}</span>
          </label>
          {!multiple &&
            <div className="form__input">
              <select
                value={value}
                className="form__input__select"
                onChange={this._onChange}
              >
                {this._getOptions()}
              </select>
            </div>
          }
          {multiple &&
            <div className="form__input__checkbox-group">
              {this._getCheckbox()}
            </div>
          }
        </div>
        {openedModal &&
          <div className="select-manage__modal">
            <div className="select-manage__modal__wrapper">
              <header className="select-manage__modal__header">
                <h2 className="select-manage__modal__title">{label}</h2>
                <span className="select-manage__modal__close" onClick={this._closeModal}>fechar</span>
              </header>
              <div className="select-manage__modal__content">
                <div className="select-manage__modal__content__form">
                  <FormControl
                    label="Novo item"
                    value={value}
                    onChange={(_, value) => this.setState({ value })}
                    onEnter={this._handleSubmit}
                  />
                  <div className="form__buttons">
                    <ButtonSpinner
                      onClick={this._handleSubmit}
                      disabled={sending}
                      type='button'
                    >
                      {translate('actions.save')}
                    </ButtonSpinner>
                  </div>
                </div>
                <div className="select-manage__modal__content__list">
                  {this._getItems()}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  };

  _getClasses = () => {
    let classes = ['form__control']
    if (this.props.errors.length > 0) {
      classes.push('form__control--error')
    }
    return classes.join(' ')
  };

  _getErrors = () => {
    if (this.props.errors.length > 0) {
      const errors = this.props.errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))
      return <ul className="form__control__errors">{errors}</ul>
    }
  };

  _onChange = event => {
    const value = event.target.value
    this.setState({ value }, () => {
      this.props.onChange(value)
    })
  };

  _openModal = () => {
    this.setState({ openedModal: true }, () => {
      this._disableBodyScroll()
    })
  };

  _closeModal = () => {
    this.setState({ openedModal: false }, () => {
      this._enableBodyScroll()
    })
  };

  _handleSubmit = () => {
    const { modelName, token } = this.props
    const { value } = this.state
    if (value) {
      const data = { name: value }
      const map = {
        fuel: 'createFuel',
        gear: 'createGear',
        color: 'createColor',
        optional: 'createOptional'
      }
      const fnName = map[modelName]
      this.props[fnName](data, token)
      this.setState({ value: '' })
    }
  };

  _getDisabled = () => {
    const { modelName } = this.props
    return this.props[modelName].sending
  };

  _getOptions = () => {
    if (this._getDisabled()) {
      return (<option>Carregando...</option>)
    } else {
      const { modelName } = this.props
      const option = <option key={0} value="">Selecione</option>
      return [option].concat(
        this.props[modelName].list
          .map(item => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            )
          })
      )
    }
  };

  _getItems = () => {
    if (this._getDisabled()) {
      return (<p>Carregando...</p>)
    } else {
      const { modelName } = this.props
      return this.props[modelName].list
        .map(item => {
          return (
            <div
              key={item.id}
              className="select-manage__modal__content__list__item"
            >
              <RIEInput
                className="edit-in-place"
                value={item.name}
                propName='name'
                validate={value => value !== ''}
                change={value => this._onEditInPlace(item, value.name)}
              />
              <span
                onClick={() => this._confirmDelete(item)}
                className="select-manage__modal__content__list__item__delete"
              >
                <img src={closeIcon} alt="Delete" />
              </span>
            </div>
          )
        })
    }
  };

  _onEditInPlace = (item, value) => {
    if (value) {
      const { modelName, token } = this.props
      const data = { id: item.id, name: value }
      const map = {
        fuel: 'updateFuel',
        gear: 'updateGear',
        color: 'updateColor',
        optional: 'updateOptional'
      }
      const fnName = map[modelName]
      this.props[fnName](data, token)
    }
  };

  _confirmDelete = (item) => {
    if (window.confirm(translate('actions.areYouSure'))) {
      const { modelName, token } = this.props
      const map = {
        fuel: 'deleteFuel',
        gear: 'deleteGear',
        color: 'deleteColor',
        optional: 'deleteOptional'
      }
      const fnName = map[modelName]
      this.props[fnName](item.id, token)
    }
  };

  _disableBodyScroll = () => {
    document.querySelector('body').classList.add('no-scroll')
  };

  _enableBodyScroll = () => {
    document.querySelector('body').classList.remove('no-scroll')
  };

  _getCheckbox = () => {
    if (this._getDisabled()) {
      return (<option>Carregando...</option>)
    } else {
      const { modelName } = this.props
      let { checkeds } = this.state
      return this.props[modelName].list
        .map(item => {
          const checked = checkeds.includes(item.id)
          return (
            <label
              key={item.id}
              className="form__input__checkbox"
            >
              <input
                type="checkbox"
                value={item.id}
                checked={checked}
                onChange={event => {
                  const value = +event.target.value
                  if (checkeds.includes(value)) {
                    checkeds = checkeds.filter(_value => _value !== value)
                  } else {
                    checkeds.push(value)
                  }
                  this.setState({ checkeds }, () => {
                    this.props.onChange(checkeds)
                  })
                }}
              />
              <span>{item.name}</span>
            </label>
          )
        })
    }
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.user.user.token,
    fuel: state.fuel,
    gear: state.gear,
    color: state.color,
    optional: state.optional
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadFuels: (token) => dispatch(loadFuels(token)),
    createFuel: (data, token) => dispatch(createFuel(data, token)),
    updateFuel: (data, token) => dispatch(updateFuel(data, token)),
    deleteFuel: (id, token) => dispatch(deleteFuel(id, token)),

    loadGears: (token) => dispatch(loadGears(token)),
    createGear: (data, token) => dispatch(createGear(data, token)),
    updateGear: (data, token) => dispatch(updateGear(data, token)),
    deleteGear: (id, token) => dispatch(deleteGear(id, token)),

    loadColors: (token) => dispatch(loadColors(token)),
    createColor: (data, token) => dispatch(createColor(data, token)),
    updateColor: (data, token) => dispatch(updateColor(data, token)),
    deleteColor: (id, token) => dispatch(deleteColor(id, token)),

    loadOptionals: (token) => dispatch(loadOptionals(token)),
    createOptional: (data, token) => dispatch(createOptional(data, token)),
    updateOptional: (data, token) => dispatch(updateOptional(data, token)),
    deleteOptional: (id, token) => dispatch(deleteOptional(id, token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectManage)
