import v8n from 'v8n'
import validatorUtils from './validation_utils'

export default {
  create (data) {
    return v8n()
      .schema({
        brand_id: v8n().not.null().number(),
        model_id: v8n().not.null().number(),
        version_id: v8n().not.null().number(),
        year_id: v8n().not.null().number(),
        fuel_id: v8n().not.null().number(),
        gear_id: v8n().not.null().number(),
        color_id: v8n().not.null().number(),
        km: v8n().not.null(),
        ports: v8n().not.null().number(),
        price: v8n().not.null().number(),
        description: v8n().not.null().minLength(1)
      })
      .testAsync(data)
      .then(result => ({ success: true }))
      .catch(e => validatorUtils.buildErrors('cars', e))
  },
  update (data) {
    return v8n()
      .schema({
        brand_id: v8n().not.null().number(),
        model_id: v8n().not.null().number(),
        version_id: v8n().not.null().number(),
        year_id: v8n().not.null().number(),
        fuel_id: v8n().not.null().number(),
        gear_id: v8n().not.null().number(),
        color_id: v8n().not.null().number(),
        km: v8n().not.null(),
        ports: v8n().not.null().number(),
        price: v8n().not.null().number(),
        description: v8n().not.null().minLength(1)
      })
      .testAsync(data)
      .then(result => ({ success: true }))
      .catch(e => validatorUtils.buildErrors('cars', e))
  }
}
